.OcWXPW_container {
  background: oklch(74% .02 145);
  width: 100dvw;
  height: 100dvh;
}

.OcWXPW_panels {
  width: 300px;
  position: absolute;
  top: 0;
  right: 0;
}

.DOq8UG_container {
  grid-template: auto / minmax(400px, auto);
  place-content: center;
  position: fixed;
  inset: 0;
}

.DOq8UG_container::backdrop {
  background: color-mix(in srgb, var(--color-gray-s3) 90%, transparent);
}

.DOq8UG_container[open] {
  display: grid;
}

.Ml_BtW_container {
  background: var(--color-gray-s5);
  color: var(--color-gray-t5);
}

.caB0bW_container {
  padding: 10px;
}

.gsidWq_container {
  border-bottom: 1px solid var(--color-gray-s4);
  grid-template-columns: 1fr auto;
  gap: 5px;
  padding: 10px;
  display: grid;
}

.gsidWq_title {
  color: oklch(100% 0, 0);
}

._3ynNkG_button {
  background: var(--color-gray-00);
  color: var(--color-gray-t5);
  padding: .25rlh 1ch;
}

._3ynNkG_button:focus {
  outline: 2px solid var(--color-gray-t5);
}

._4B9Guq_container {
  border-top: 1px solid var(--color-gray-s4);
  padding: 10px;
}

.cy63yG_container {
  gap: 5px;
  display: flex;
}

.cy63yG_left {
  flex-direction: row;
}

.cy63yG_right {
  flex-direction: row-reverse;
}

._KaQXW_container {
  gap: 5px;
  display: grid;
}

.Ahel4a_container {
  animation: .5s linear infinite Ahel4a_SpinnerRotation;
}

@keyframes Ahel4a_SpinnerRotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.uvomEG_container {
  gap: 5px;
  padding: 5px;
  display: grid;
}
/*# sourceMappingURL=index.c884cef3.css.map */
